import { AuthProvider } from 'react-admin';
import { queryLogin, queryProfile } from 'query/auth';
import { JwtUtil } from 'utils/jwt.utils';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const loginResponse = await queryLogin({ username, password });
      const { accessToken, refreshToken, expiresIn } = loginResponse;
      JwtUtil.setAuthInfo({ accessToken, refreshToken, expiresIn });

      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  },
  logout: () => {
    JwtUtil.clearAuthInfo();
    return Promise.resolve();
  },
  checkError: error => {
    const status = error?.status;
    if (status === 401 || status === 403) {
      JwtUtil.clearAuthInfo();
      return Promise.reject();
    }
    // other error code (404, 500, etc.): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => JwtUtil.isAuthenticated(),

  getPermissions: () => Promise.reject('Unknown method'),
  getIdentity: async () => {
    try {
      const response = await queryProfile();
      return Promise.resolve({ ...response, fullName: response.phone });
    } catch (error) {
      return Promise.reject();
    }
  },
};

export default authProvider;

import * as React from 'react';
import { Datagrid, DateField, EditButton, List, SearchInput, TextField } from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';
import { UserLevelField } from 'modules/users/components/atoms/UserLevelField';
import MobileGrid from 'modules/users/components/molecules/MobileGrid';
import { useGetIdentity } from 'ra-core';
import { UserLevel } from 'types/user';

import { CustomBooleanField } from 'components/atoms/CustomBooleanField';
import { DeleteWithConfirmButtonField } from 'components/atoms/DeleteWithConfirmButtonField';

const filters = [<SearchInput key={1} source="q" alwaysOn name="" />];

const UserList = () => {
  const isXsmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const { identity } = useGetIdentity();

  return (
    <List filters={filters} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="show"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          <TextField source="username" label="resources.users.fields.username" />
          <UserLevelField source="level" label="resources.users.fields.level" />
          <CustomBooleanField source="tester" label="resources.users.fields.tester" textAlign="center" />
          <DateField source="createdAt" label="resources.users.fields.created_at" />
          <DateField source="updatedAt" label="resources.users.fields.updated_at" />
          <DateField source="loggedInAt" label="resources.users.fields.logged_in_at" />
          <DeleteWithConfirmButtonField nameField="username" />
          {identity?.level === UserLevel.SuperUser && <EditButton />}
        </Datagrid>
      )}
    </List>
  );
};

export default UserList;

import * as React from 'react';
import { ElementType, FC, memo } from 'react';
import { FieldProps, sanitizeFieldRestProps, useTranslate } from 'react-admin';
import Typography, { TypographyProps } from '@mui/material/Typography';
import get from 'lodash/get';
import { useRecordContext } from 'ra-core';
import { fieldPropTypes } from 'types/field';

export const UserLevelField: FC<UserLevelField> = memo(props => {
  const translate = useTranslate();

  const { className, source, emptyText, ...rest } = props;
  const record = useRecordContext(props);
  const value = translate(
    `resources.users.level.${get(record, source || '')
      ?.toString()
      ?.toLowerCase()}`
  );

  return (
    <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
      {value || emptyText}
    </Typography>
  );
});

// what? TypeScript loses the displayName if we don't set it explicitly
UserLevelField.displayName = 'TextField';

UserLevelField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface UserLevelField extends FieldProps, Omit<TypographyProps, 'textAlign'> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  component?: ElementType<any>;
}

import VisitorIcon from '@mui/icons-material/People';
import { UserCreate } from 'modules/users/components/pages/UserCreate';
import { UserShow } from 'modules/users/components/pages/UserShow';

import { UserEdit } from './components/pages/UserEdit';
import UserList from './components/pages/UserList';

const users = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow,
  icon: VisitorIcon,
};

export default users;

import React from 'react';
import { Create, SimpleForm, useCreate, useRedirect, useTranslate } from 'react-admin';
import { UserForm } from 'modules/users/components/molecules/UserForm';
import { useNotify } from 'ra-core';

export const UserCreate = () => {
  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleSubmit = async (data: Record<string, any>) => {
    await create(
      'admin-user',
      { data },
      {
        onSuccess() {
          notify(translate('resources.users.notify.create_success'), { type: 'success' });
          redirect('/users');
        },
        onError() {
          notify(translate('resources.users.notify.create_failed'), { type: 'error' });
        },
      }
    );
  };

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <UserForm />
      </SimpleForm>
    </Create>
  );
};

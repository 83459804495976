// in src/comments.js
import * as React from 'react';
import { DateField, RecordContextProvider, useListContext, useTranslate } from 'react-admin';
import { Box, Card, CardContent, CardHeader, Chip } from '@mui/material';
import { UserLevelField } from 'modules/users/components/atoms/UserLevelField';
import { User } from 'types/user';

const MobileGrid = () => {
  const translate = useTranslate();
  const { data, isLoading } = useListContext<User>();

  if (isLoading || data.length === 0) {
    return null;
  }

  return (
    <Box margin="0.5em">
      {data.map(record => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: '0.5rem 0' }}>
            <CardHeader
              title={
                <>
                  {record.phone}
                  {record.tester && <Chip sx={{ ml: 1 }} label={translate('resources.users.fields.tester')} />}
                </>
              }
              subheader={
                <>
                  <UserLevelField source="level" />
                </>
              }
            />
            <CardContent sx={{ pt: 0 }}>
              {translate('resources.users.fields.created_at')}: &nbsp;
              <DateField source="createdAt" />
              <br />
              {translate('resources.users.fields.updated_at')}: &nbsp;
              <DateField source="updatedAt" />
              <br />
              {translate('resources.users.fields.logged_in_at')}: &nbsp;
              <DateField source="loggedInAt" />
              <br />
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;

import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField, TopToolbar, useShowController } from 'react-admin';
import { UserLevelField } from 'modules/users/components/atoms/UserLevelField';
import UserTitle from 'modules/users/components/atoms/UserTitle';

import { CustomBooleanField } from 'components/atoms/CustomBooleanField';
import { DeleteWithConfirmButtonField } from 'components/atoms/DeleteWithConfirmButtonField';

export const UserShow = () => {
  const { record } = useShowController();
  if (!record) return null;

  return (
    <Show
      title={<UserTitle record={record} />}
      actions={
        <TopToolbar>
          <DeleteWithConfirmButtonField nameField="username" />
        </TopToolbar>
      }
    >
      <SimpleShowLayout>
        <TextField source="username" label="resources.users.fields.username" />
        <UserLevelField source="level" label="resources.users.fields.level" />
        <CustomBooleanField source="tester" label="resources.users.fields.tester" textAlign="center" />
        <DateField source="createdAt" label="resources.users.fields.created_at" />
        <DateField source="updatedAt" label="resources.users.fields.updated_at" />
        <DateField source="loggedInAt" label="resources.users.fields.logged_in_at" />
      </SimpleShowLayout>
    </Show>
  );
};

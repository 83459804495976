import React, { useMemo } from 'react';
import { required, SelectInput, TextInput, useTranslate } from 'react-admin';
import { Box } from '@mui/material';
import { UserLevel } from 'types/user';

export const BasicInfoForm: React.FC = () => {
  const translate = useTranslate();

  const choices = useMemo(
    () =>
      Object.values(UserLevel).map(userLevel => ({
        label: translate(`resources.users.level.${userLevel?.toString()?.toLowerCase()}`),
        value: userLevel,
      })),
    [UserLevel]
  );

  return (
    <Box display="flex" flexDirection="column" width={'100%'} gap={2}>
      <Box display="flex" flexDirection="row" width={'100%'}>
        <TextInput
          variant="outlined"
          label={'Username'}
          source="username"
          validate={[required()]}
          placeholder="Enter Username"
          fullWidth
          disabled
        />
      </Box>
      <Box display="flex" flexDirection="row" width={'100%'}>
        <SelectInput
          variant="outlined"
          label={'Level'}
          source="level"
          validate={[required()]}
          placeholder="Enter Password"
          defaultValue={UserLevel.Admin}
          choices={choices}
          optionText="label"
          optionValue="value"
          fullWidth
          disabled
        />
      </Box>
    </Box>
  );
};

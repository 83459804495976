import * as React from 'react';
import { useTranslate } from 'react-admin';
import { Chip } from '@mui/material';
import { User } from 'types/user';

export interface UserTitleProps {
  record: User;
}

const UserTitle = ({ record }: UserTitleProps) => {
  const translate = useTranslate();

  return (
    <>
      {record?.phone}
      {record?.tester && <Chip sx={{ ml: 1 }} label={translate('resources.users.fields.tester')} />}
    </>
  );
};

export default UserTitle;

import React from 'react';
import { Edit, TabbedForm, useRedirect, useShowController, useUpdate } from 'react-admin';
import UserTitle from 'modules/users/components/atoms/UserTitle';
import { BasicInfoForm } from 'modules/users/components/molecules/BasicInfoForm';
import { ChangePasswordForm } from 'modules/users/components/molecules/ChangePasswordForm';
import { useGetIdentity, useNotify } from 'ra-core';
import { UpdateType } from 'types/common';
import { UserLevel } from 'types/user';

export const UserEdit = () => {
  const [update] = useUpdate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { identity } = useGetIdentity();
  const { record } = useShowController();
  if (!record) return null;

  const handleSubmit = async (data: Record<string, any>) => {
    update(
      `admin-user/${UpdateType.UserPassword}`,
      {
        id: data.id,
        data: {
          password: data.newPassword,
        },
      },
      {
        onSuccess: () => {
          notify('resources.users.notify.update_password_success', { type: 'success' });
          redirect('/users');
        },
        onError: () => notify('resources.users.notify.update_password_failed', { type: 'error' }),
      }
    ).then();
  };

  return (
    <Edit title={<UserTitle record={record} />}>
      <TabbedForm onSubmit={handleSubmit}>
        <TabbedForm.Tab label="resources.users.tabs.basic_info">
          <BasicInfoForm />
        </TabbedForm.Tab>
        {identity?.level === UserLevel.SuperUser && (
          <TabbedForm.Tab label="resources.users.tabs.password">
            <ChangePasswordForm />
          </TabbedForm.Tab>
        )}
      </TabbedForm>
    </Edit>
  );
};

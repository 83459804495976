import * as React from 'react';
import { MenuItemLink, MenuProps, useSidebarState, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
import users from 'modules/users';

const Menu = ({ dense = false }: MenuProps) => {
  const translate = useTranslate();
  const [open] = useSidebarState();

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <MenuItemLink
        to="/users"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.users.name`, {
          smart_count: 2,
        })}
        leftIcon={<users.icon />}
        dense={dense}
      />
    </Box>
  );
};

export default Menu;
